
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    formDataSetup: {},
    option: "",
  },
})
export default class ProductOptionChild extends Vue {
  options = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  quantityTypes = [
    {
      value: 'Absolute',
      label: 'Absolute',
    },
    {
      value: 'Relative',
      label: 'Relative',
    },
    {
      value: 'Available',
      label: 'Available',
    },
    {
      value: 'InStock',
      label: 'InStock',
    },
    {
      value: 'UnShipped',
      label: 'UnShipped',
    },
    // {
    //   value: 'Unconfirmed',
    //   label: 'Unconfirmed',
    // },
  ]

  inputVisible = false;
  visible = false;

  inputValue = "";
  handleClose(tag: any) {
    this.$emit("removeSKU", tag);
  }
  showInput() {
    this.inputVisible = true;
  }
  handleInputConfirm() {
    const inputValue = this.inputValue;
    if (inputValue) {
      this.$emit("pushSKU", this.inputValue);
    }
    this.inputVisible = false;
    this.inputValue = "";
  }
  getURLShopifyWebhook(formDataSetup:any = {}) {
    let cronId = formDataSetup?._id
    let connectFromType = formDataSetup?.connectFrom?.type
    let shopifyWebHook = `${process.env.VUE_APP_BASE_URL}/${connectFromType}`
    if (connectFromType === 'xentral') {
      shopifyWebHook += '/purchase-price-webhook'
    } else {
      shopifyWebHook += '/delete-webhook'
    }
    shopifyWebHook = shopifyWebHook.replace('dev.iomnic.com', 'wiserobot:d3v3l0p@dev.iomnic.com')
    return `${shopifyWebHook}/${cronId}`;
  }
  copyURLShopifyWebhook(cronId = '') {
    navigator.clipboard.writeText(this.getURLShopifyWebhook(cronId));
  }
}
